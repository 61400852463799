import {
  Box,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  Button,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Flag from "./flag.png";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [usageNumber, setUsageNumber] = React.useState(0);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/updater/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=67d57806-5bfe-491e-b9ca-e218a7a983d6&redirectUrl=https://certifiedcode.wixsite.com/updater/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl =  `https://www.wix.com/apps/upgrade/${"67d57806-5bfe-491e-b9ca-e218a7a983d6"}?appInstanceId=${
    (instanceData as any)["instance"]["instanceId"]
  }`

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "All features of Updater without "Powered By" badge"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Let site members edit login email easily"
                content="Updater is an app that allows site members to update their login email address easily. To set it up, go to Editor > Add Panel (+) > App Widget > Updater"
                actions={
                  <Box direction="vertical" gap="SP2">
                    <Button
                      skin="premium"
                      prefixIcon={<Icons.PremiumFilled />}
                      onClick={() => {
                        window.open(upgradeUrl);
                      }}
                    >
                      {isUpgraded ? "Manage Subscription" : "Remove Powered By Badge"}
                    </Button>
                    <Button
                      skin="inverted"
                      as="a"
                      href="https://www.wix.com/app-market/add-review/67d57806-5bfe-491e-b9ca-e218a7a983d6"
                      target="_blank"
                    >
                      Rate Updater 5 stars
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Flag} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
